<template>
    <div class="field categories flex">
        <div class="flex-between selector">
            <span class="title">Categories:</span>
            <BaseDropDownBtn :unactiveItemsList="categoriesListToRequest"
                             v-if="list.length"
                             :list="list"
                             @change="onChange">
            </BaseDropDownBtn>
            <BaseButton class="btn-primary empty add-category-btn" @click="appendCategory">+</BaseButton>
        </div>
        <div class="categories-list flex">
            <div class="category"
                 v-for="(item, index) in categoriesListToRequest"
                 :key="index"
                 :title="item.selectorTitle">
                <span>{{item.selectorTitle}}</span>
                <div class="remove" @click="removeCategory(item)">X</div>
            </div>
            <div class="clear-categories" v-if="categoriesListToRequest.length > 1" @click="clearCategoriesListToRequest">X</div>
        </div>

    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    export default {
        name: "AddCategoryToVideoComponent",
        data(){
            return {
                list: [{
                    selectorTitle: 'Не выбрано',
                    category: {
                        id: ''
                    },
                }],
                categoriesListToRequest: [],
                selectedCategory: '',
            }
        },
        methods: {
            ...mapActions(['GET_CATEGORIES_FOR_NEW_VIDEO']),
            onChange(val){
                this.selectedCategory = val;
            },
            appendCategory(){
                if (!this.selectedCategory || !this.selectedCategory.category.id) return;

                this.categoriesListToRequest.push(this.selectedCategory);
                this.resetDropdown();
                this.$emit('change', this.getIdsArr());
            },
            getIdsArr(){
                return this.categoriesListToRequest.map((item)=>{
                    return parseInt(item.category.id)
                })
            },
            resetDropdown(){
                this.selectedCategory = '';
                this.$emit('setActiveItemOnDropdown', 0);
            },
            removeCategory(category){
                this.categoriesListToRequest.splice(this.categoriesListToRequest.findIndex((item)=>{
                    return item.category.id === category.category.id
                }), 1);

                this.$emit('change', this.getIdsArr());
            },
            clearCategoriesListToRequest(){
                this.categoriesListToRequest = [];
                this.$emit('change', this.getIdsArr());
            }
        },
        mounted() {
            this.GET_CATEGORIES_FOR_NEW_VIDEO()
                .then((response)=>{
                    this.list.push(...response.map((val)=>{
                        return {
                            selectorTitle: val.title,
                            category: val
                        }
                    }))
                })
        }
    }
</script>

<style lang="scss" scoped>
    .field {
        min-height: 40px;
        flex-wrap: wrap;

        .btn {
            width: auto;
        }
        span {
            min-width: 100px;
            font-weight: bold;
            i {
                color: red;
            }
        }
        .add-category-btn {
            height: 52px;
            margin-left: 5px;
            font-size: 25px;
            font-weight: 500;
            padding: 0 20px;
        }
        .selector {
            width: 100%;
            align-items: center;
            @media (max-width: 768px) {
                flex-wrap: wrap;
            }
        }
        .categories-list{
            width: 100%;
            flex-wrap: wrap;
            padding: 5px 25px 0 105px;
            position: relative;
            @media (max-width: 768px) {
                padding: 5px 25px 0 20px;
            }
        }
        .clear-categories {
            font-size: 16px;
            width: 10px;
            text-align: center;
            line-height: 1em;
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 14px;
            font-weight: bold;
            /*color: #89a7c1;*/
        }
        .category {
            padding: 5px 28px 5px 0;
            /*border: 1px solid #89a7c1;*/
            /*border-radius: 4px;*/
            color: #424242;
            font-weight: 500;
            max-width: 45%;
            line-height: 1em;
            display: flex;
            align-items: center;
            position: relative;
            margin: 2px 5px 2px 0;
            @media (max-width: 768px) {
                max-width: 90%;
            }
            span {
                min-width: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 20px;
                align-items: center;
                font-weight: 500;
            }
            .remove {
                font-size: 12px;
                width: 10px;
                text-align: center;
                line-height: 1em;
                cursor: pointer;
                position: absolute;
                right: 10px;
                top: 10px;
                color: #89a7c1;
            }
        }
    }
    ::v-deep .dropdown {
        height: 52px;
        border: 1px solid #afc7da;
        border-radius: 2px;
        padding: 0 20px;
        flex: 1;
        span {
            font-weight: 500;
            opacity: 1;
        }
        &.selected {
            span {
                opacity: 1;
            }
        }
        .list {
            width: 100%;
            background: #fff;
            top: 53px;
            left: 0;
            border: 1px solid #afc7da;
            overflow-y: scroll;
            height: 200px;

            .list-item {
                padding: 7px 20px;

                &.active {
                    background-color: #e8e8e8;
                }
            }
        }
    }
</style>
