<template>
    <div class="keywords-wrapper flex">
        <div class="keywords"
             v-for="(item, index) in keywordsList"
             :key="index"
             :title="item">
            <span>{{item}}</span>
            <div class="remove" @click="removeKeyword(item)">X</div>
        </div>
        <BaseInput  placeholder="Введите слово..."
                    v-model.trim="keyword"
                    @keyup="checkSeparator"
        >
        </BaseInput>
        <div class="clear-all"
             title="Очистить все"
             v-if="keywordsList.length > 1"
             @click="clearKeywordsList"
        >X</div>
    </div>

</template>

<script>
    export default {
        name: "AddKeywordsToVideoComponent",
        data(){
            return {
                keyword: '',
                keywordsList: [],
            }
        },
        methods:{
            clearKeywordsList(){
                this.keywordsList = [];
                this.$emit('change', this.keywordsList);
            },
            checkSeparator(e){
                if (!this.keyword.length) return;

                if (e.code === 'Enter') {
                    this.addKeyword();
                    return;
                }
                if (e.key === ',') {
                    this.keyword = this.keyword.slice(0,-1);
                    this.keyword.length ? this.addKeyword() : null;
                }
            },
            addKeyword(){
                let wordIsPresentNow = this.keywordsList.find((keyword)=>{
                    return keyword === this.keyword;
                });

                if (wordIsPresentNow) return;

                this.keywordsList.push(this.keyword);
                this.keyword = '';
                this.$emit('change', this.keywordsList);
            },
            removeKeyword(keyword){
                this.keywordsList.splice(this.keywordsList.findIndex((item)=>{
                    return item === keyword
                }), 1);

                this.$emit('change', this.keywordsList);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .keywords-wrapper {
        width: 100%;
        padding: 10px 20px;
        background-color: #fff;
        border: 1px solid #afc7da;
        color: #89a7c1;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        flex-wrap: wrap;
        position: relative;

        ::v-deep label {
            height: 36px!important;
            /*width: auto;*/
            max-width: 45%;
            @media (max-width: 600px) {
                min-width: 100%;
            }
            input {
                border: none;
                padding: 0;
                height: 36px;
            }
        }
    }

    .keywords {
        padding: 5px 35px 5px 10px;
        border: 1px solid #89a7c1;
        border-radius: 4px;
        color: #424242;
        font-weight: 500;
        max-width: 45%;
        line-height: 1em;
        display: flex;
        align-items: center;
        position: relative;
        margin: 2px 10px 2px 0;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 20px;
            align-items: center;
        }
        .remove {
            font-size: 12px;
            font-weight: bold;
            width: 10px;
            text-align: center;
            line-height: 1em;
            cursor: pointer;
            position: absolute;
            right: 10px;
            color: #89a7c1;
        }
    }
    .clear-all{
        cursor: pointer;
        padding: 0 5px;
        position: absolute;
        right: 12px;
        top: 14px;
        font-weight: bold;
        color: #424242;
    }
</style>
