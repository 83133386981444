<template>
    <div class="wrapper flex">
        <div class="content-container">
            <AuthFormErrorComponent :error="mainError"></AuthFormErrorComponent>
            <div class="flex-between inner">
                <div class="video-preview-poster">
                    <img :src="formData.image" alt="">
<!--                    <div class="overlay" v-if="formData.video">-->
<!--                        <img src="../../assets/img/icons8_circled_play_64px.png" alt="" @click="playVideo">-->
<!--                    </div>-->
                </div>
                <div class="inputs-wrapper">
                    <div class="field video-url flex-between">
                        <span class="title">VIDEO <br> URL: <i>*</i></span>
                        <div class="file-name" v-if='files.video'>
                            {{files.video.name}}
                            <span class="remove-file" @click="removeVideoFile">X</span>
                        </div>
                        <BaseInput v-else
                                   v-model.trim="formData.video_url"
                                   :error="errors.video_url">
                        </BaseInput>
                        <BaseButton class="btn-primary empty">File + <label for="add-video"></label></BaseButton>
                        <input ref="videoLabel"
                               type="file"
                               id="add-video"
                               class="file-input"
                               accept="video/mp4,video/x-m4v,video/*"
                               @change="prepareVideo"
                        >
                    </div>
                    <div class="field video-title flex-between">
                        <span class="title">TITLE: <i>*</i></span>
                        <BaseInput v-model.trim="formData.title"
                                   maxlength="70"
                                   :error="errors.title">
                        </BaseInput>
                    </div>
                    <div class="field poster-url flex-between">
                        <span class="title">POSTER <br> URL: <i>*</i></span>
                        <div class="file-name" v-if='files.image'>
                            {{files.image.name}}
                            <span class="remove-file" @click="removePosterFile">X</span>
                        </div>
                        <BaseInput v-else
                                   v-model.trim="formData.image_url"
                                   :error="errors.image_url">
                        </BaseInput>
                        <BaseButton class="btn-primary empty">File + <label for="add-poster"></label></BaseButton>
                        <input ref="imageLabel"
                               type="file"
                               id="add-poster"
                               class="file-input"
                               accept="image/*"
                               @change="setPoster">
                    </div>
                    <div class="field click-url flex-between">
                        <span class="title">Click URL:</span>
                        <BaseInput v-model.trim="formData.click_url"
                                   :error="errors.click_url">
                        </BaseInput>
                    </div>
                    <AddLanguageToVideoComponent @change="setLanguage"></AddLanguageToVideoComponent>
                    <AddCategoryToVideoComponent @change="setCategory"></AddCategoryToVideoComponent>
                </div>
            </div>
            <div class="inputs-wrapper keywords-wrapper">
                <div class="field keywords flex-between">
                    <span class="title">Keywords:</span>
                    <AddKeywordsToVideoComponent @change="setKeywords"></AddKeywordsToVideoComponent>
                </div>
                <div class="progress-indicator-wrapper">
                    <div class="progress-indicator-inner" v-if="showUploadIndicator">
                        <div class="progress" :style="{'width': uploadProgress + '%'}"></div>
                        <span>{{uploadProgress + '%'}}</span>
                    </div>
                </div>
                <div class="flex-end submit-buttons">
                    <BaseButton class="cancel-btn btn-primary empty" @click="cancel">Cancel</BaseButton>
                    <BaseButton class="btn-primary" @click="submit" :processing="loading">Save</BaseButton>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapActions} from 'vuex';
    import AuthFormErrorComponent from "../authorisation/AuthFormErrorComponent";
    // import PlayerComponent from "../PlayerComponent";
    import AddLanguageToVideoComponent from "./AddLanguageToVideoComponent";
    import AddCategoryToVideoComponent from "./AddCategoryToVideoComponent";
    import AddKeywordsToVideoComponent from "./AddKeywordsToVideoComponent";
    import _ from 'lodash';

    export default {
        name: "AddNewVideoComponent",
        data(){
            return {
                loading: false,
                mainError: '',
                files:{
                    video: null,
                    image: null,
                },
                formData: {
                    video: '',
                    image: '',
                    video_url: '',
                    image_url: '',
                    title: '',
                    click_url: '',
                    language_id: '',
                    categories: [],
                    keywords: [],
                },
                errors: {
                    video_url: '',
                    image_url: '',
                    title: '',
                    click_url: '',
                    language_id: '',
                    categories: '',
                    keywords: '',
                },
                errorText:{
                    emptyField:'Поле должно быть заполнено',
                    notValidURL:'Введите корректный web-адрес',
                },
                showUploadIndicator: false
            }
        },
        methods:{
            ...mapActions(['VALIDATE_VIDEO_BEFORE_SEND','ADD_NEW_VIDEO']),
            setPoster(e){
                this.addFile(e, 'image');
                this.formData.image_url = '';
            },
            prepareVideo(e){
                // this.addFile(e, 'video');
                this.files.video = e.target.files[0];
                this.formData.video = e.target.files[0];
                this.formData.video_url = '';
            },
            addFile(e, type){
                if (e.target.files && e.target.files[0]) {
                    this.clearErrors();
                    this.files[type] = e.target.files[0];
                    let reader = new FileReader();
                    reader.onload = (reader) => {
                        this.formData[type] = reader.target.result;
                    };
                    reader.readAsDataURL(e.target.files[0]);
                }
            },
            cancel(){
                this.$store.state.axiosCancel(); //Костыль. Прерывание загрузки видео
                this.$router.push(this.$store.getters.dashBoardRoute('videos'));
            },
            submit(){
                this.clearErrors();
                this.validateForm(()=>{
                    this.loading = true;
                    this.VALIDATE_VIDEO_BEFORE_SEND(this.createValidateRequestObj())
                        .then(()=>{
                            this.clearErrors();
                            this.formData.video ? this.runUploadIndicator() : null;

                            this.ADD_NEW_VIDEO(this.createFormDataToRequest())
                                .then(()=>{
                                    setTimeout(()=>{
                                        this.$router.push({name : 'videos', params: {resetCache: true, initSearchQuery: this.formData.title}});
                                    }, 500);
                                })
                                .catch((err)=>{
                                    this.loading = false;
                                    this.setErrorsAfterValidate(err);
                                });
                        })
                        .catch((err)=>{
                            this.loading = false;
                            this.setErrorsAfterValidate(err);
                        })
                });
            },
            runUploadIndicator(){
                this.$store.commit('SET_UPLOAD_PROGRESS_PERCENT', 0);
                this.showUploadIndicator = true;
            },
            createValidateRequestObj(){
                let obj = {};
                this.formData.image_url ? obj.image_url = this.formData.image_url : null;
                this.formData.video_url ? obj.video_url = this.formData.video_url : null;

                return Object.assign(obj, {
                    title: this.formData.title,
                    click_url: this.formData.click_url,
                    language_id: this.formData.language_id,
                },
                    this.fillArrayParamsToRequest('keywords', this.formData.keywords),
                    this.fillArrayParamsToRequest('categories', this.formData.categories)
                )
            },
            fillArrayParamsToRequest(name, data){
                // чтобы сервер смог переварить массив
                // получается конструкция типу {categories[0]: 33, categories[1]: 68} или {keywords[0]: 'сложна', keywords[1]: 'сложна', keywords[2]: 'ничего', keywords[3]: 'нипанятна'}
                let obj = {};
                data.forEach((val, key)=>{
                     obj[`${name}[${key}]`] = val;
                });
                return obj;
            },
            fillFormDataParamsToRequest(form, name, data){
                data.forEach((val, key)=>{
                    form.append([`${name}[${key}]`], val)
                });
            },
            createFormDataToRequest(){
                let form = new FormData();

                _.forIn(this.formData, (val, key) => {
                    switch(key) {
                        case 'video': {
                            this.files.video ? form.append('video_file', this.files.video) : null;
                            break
                        }
                        case 'image': {
                            this.files.image ? form.append('image_file', this.files.image) : null;
                            break
                        }
                        case 'keywords': {
                            this.fillFormDataParamsToRequest(form, 'keywords', this.formData.keywords);
                            break
                        }
                        case 'categories': {
                            this.fillFormDataParamsToRequest(form, 'categories', this.formData.categories);
                            break
                        }
                        default:
                            val && val.length || _.isNumber(val) ? form.append(key, val) : null;

                    }
                });

                return form;
            },
            setErrorsAfterValidate(err){
                _.forIn(err.errors || err.error, (value, key)=>{
                    this.mainError+=value + '<br>';
                    this.errors[key] = true;
                })
            },
            clearErrors(){
                this.mainError = '';
                _.forEach(this.errors, (val, key, source) => {
                    source[key] = '';
                })
            },
            removeVideoFile(){
                this.files.video = null;
                this.formData.video = null;
                this.$refs.videoLabel.value = null;
            },
            removePosterFile(){
                this.files.image = null;
                this.formData.image = null;
                this.$refs.imageLabel.value = null;
            },
            // playVideo(){
            //     this.$modal.show(PlayerComponent, {url: this.formData.video}, {
            //         height: 'auto',
            //         width: window.innerWidth > 1300 ? '60%' : '100%',
            //         adaptive: false,
            //         reset: true,
            //         resizable: false
            //     }, {
            //         // 'before-close': (event) => { console.log('this will be called before the modal closes'); }
            //     });
            // },
            setLanguage(val){
                this.formData.language_id = val.lang.id
            },
            setCategory(val){
                this.formData.categories = val;
            },
            setKeywords(val){
                this.formData.keywords = val;
            },
            validateForm(callback){
                if (!this.formData.video_url.length && !this.formData.video) {
                    this.mainError = this.errorText.emptyField;
                    this.errors.video_url = true;
                    return
                }
                if (!this.formData.image_url.length && !this.formData.image) {
                    this.mainError = this.errorText.emptyField;
                    this.errors.image_url = true;
                    return
                }
                if( this.formData.video_url && !this.checkIsUrl(this.formData.video_url)) {
                    this.mainError = this.errorText.notValidURL;
                    this.errors.video_url = true;
                    return
                }
                if( this.formData.image_url && !this.checkIsUrl(this.formData.image_url)) {
                    this.mainError = this.errorText.notValidURL;
                    this.errors.image_url = true;
                    return
                }
                if( this.formData.click_url && !this.checkIsUrl(this.formData.click_url)) {
                    this.mainError = this.errorText.notValidURL;
                    this.errors.click_url = true;
                    return
                }
                callback();
            },
            checkIsUrl(str){
                return str.match(/https:\/\/|http:\/\//g);
            },
        },
        computed:{
            uploadProgress(){
                return this.$store.state.uploadProgressPercent
            }
        },
        components:{
            AuthFormErrorComponent,
            AddLanguageToVideoComponent,
            AddCategoryToVideoComponent,
            AddKeywordsToVideoComponent,
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        background-color: #e8e8e8;
        align-items: center;
        .content-container {
            background-color: #ffffff;
            height: 100%;
            max-width: 1140px;
            margin: 0 auto;
            width: 100%;
            padding: 80px 35px 80px 95px;
            position: relative;
            @media (max-width: 1200px) {
                padding: 80px 35px 80px 35px;
            }
            @media (max-width: 900px) {
                padding: 80px 10px 80px 10px;
            }
            @media (max-width: 768px) {
                padding: 10px 10px 10px 10px;
            }
        }
    }
    .inner {
        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
    }
    .video-preview-poster {
        width: 40%;
        height: 340px;
        background-color: #e8e8e8;
        position: relative;
        @media (max-width: 1200px) {
            height: 250px;
        }
        @media (max-width: 900px) {
            width: 35%;
            height: 210px;
        }
        @media (max-width: 768px) {
            width: 70%;
            height: 250px;
            margin: 0 auto 10px;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
        .overlay {
           position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            top: 0;
            img {
                object-fit: contain;
                width: 50px;
                height: 50px;
                cursor: pointer;
            }
        }
    }
    .inputs-wrapper {
        width: 60%;
        padding-left: 30px;
        @media (max-width: 900px) {
            width: 65%;
        }
        @media (max-width: 768px) {
            width: 100%;
            padding-left: 0;
        }
        &.keywords-wrapper {
            padding-left: 0;
            width: 100%;
        }
        .field {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            @media (max-width: 768px) {
                flex-wrap: wrap;
            }
            label {
                flex: 1;
            }
            span {
                min-width: 100px;
                font-weight: bold;
                i {
                    color: red;
                }
            }
            &.keywords {
                max-width: 100%;
            }
            ::v-deep .title {
                @media (max-width: 768px) {
                    width: 100%;
                    margin-bottom: 5px;
                    font-size: 12px;
                    br {
                        display: none;
                    }
                }
            }
        }
        .btn {
            height: 52px;
            margin-left: 5px;
            label {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 10;
                cursor: pointer;
                top: 0;
                left: 0;
            }
            @media (max-width: 768px) {
                width: auto;
            }
        }
        ::v-deep label {
            margin: 0;
            height: 52px;
            input {
                margin: 0;

            }
        }
    }
    .file-input {
        display: none;
    }
    .file-name {
        width: 100%;
        position: relative;
        line-height: 52px;
        border: 1px solid #afc7da;
        border-radius: 2px;
        padding: 0 22px;
        color: #424242;
        height: 52px;
        background-color: #e8e8e8;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
        @media (max-width: 600px) {
            padding: 0 21px 0 10px;
        }
        .remove-file {
            position: absolute;
            top: 0;
            right: 10px;
            font-weight: bold;
            cursor: pointer;
            text-align: right;
        }
    }
    .submit-buttons {
        .btn {
            @media (max-width: 600px) {
                width: 50%;
            }
        }
        .cancel-btn {
            margin-left: 0;
        }
    }
    .progress-indicator-wrapper {
        width: 100%;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        position: relative;
        .progress-indicator-inner {
            height: 100%;
            width: 100%;
        }
        .progress{
            width: 0;
            height: 100%;
            background-color: #e8703b;

        }
        span  {
            font-size: 12px;
            line-height: 18px;
            font-weight: bold;
            position: absolute;
            z-index: 1;
            right: 0;
            top: 0;
        }
    }
</style>
