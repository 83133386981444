<template>
    <div class="field language flex-between">
        <span class="title">Language:</span>
        <BaseDropDownBtn v-if="list.length"
                         :list="list"
                         @change="changeLang">
        </BaseDropDownBtn>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    export default {
        name: "AddLanguageToVideoComponent",
        data(){
            return {
                list: [{
                    selectorTitle: 'Не выбрано',
                    lang: {
                        id: ''
                    },
                }],
            }
        },
        methods: {
            ...mapActions(['GET_LANGUAGES_FOR_NEW_VIDEO']),
            changeLang(val){
                this.$emit('change', val);
            }
        },
        mounted() {
            this.GET_LANGUAGES_FOR_NEW_VIDEO()
                .then((response)=>{
                    this.list.push(...response.map((val)=>{
                        return {
                            selectorTitle: val.title,
                            lang: val
                        }
                    }))
                })
        }
    }
</script>

<style lang="scss" scoped>
    .field {
        span {
            min-width: 100px;
            font-weight: bold;
            i {
                color: red;
            }
        }
    }
    ::v-deep .dropdown {
        height: 52px;
        border: 1px solid #afc7da;
        border-radius: 2px;
        padding: 0 20px;
        span {
            font-weight: 500;
            opacity: 1;
        }
        &.selected {
            span {
                opacity: 1;
            }
        }
        .list {
            width: 100%;
            background: #fff;
            top: 53px;
            left: 0;
            border: 1px solid #afc7da;
            overflow-y: scroll;
            height: 200px;

            .list-item {
                padding: 7px 20px;

                &.active {
                    background-color: #e8e8e8;
                }
            }
        }
    }
</style>
